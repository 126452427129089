import axios from "axios";

const BASE_URL =
  process.env.NODE_ENV === "Production"
    ? "http://localhost:6000"
    : "https://nodejs-api.enrafricacentre.net";

export default axios.create({
  baseURL: BASE_URL,
  timeout: 50000, 
});

console.log({BASE_URL});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
  timeout: 50000, 
});
